import React from 'react';

import {
  FacebookIconofWowMomos,
  InstagramIconofWowMomos,
  TwitterIconofWowMomos,
  LinkedInIconofWowMomos,
} from '../../assets/images/social';
import { $Flexbox } from '../../css/styles';

export const WowMomosSocial = () => (
  <React.Fragment>
    {/* <SocialText> Follow us on Social Media <br/>Get 10% Discount on F&B items</SocialText> */}
    <$Flexbox className="Socialalign" justify="space-around" padding="16px 0px" alignItems="center">
      <a
        href="https://www.facebook.com/WowMomos?mibextid=ZbWKwL"
        target="_blank"
        rel="noopener noreferrer">
        <FacebookIconofWowMomos style={{ width: '33px', height: '30px' }} />
      </a>
      <a
        href="https://www.instagram.com/wowmomos/profilecard/?igsh=aXJpM212bmxhaXow"
        target="_blank"
        rel="noopener noreferrer">
        <InstagramIconofWowMomos style={{ width: '33px', height: '30px' }} />
      </a>
      <a
        href="https://x.com/Wowmomo4u?t=tGU_KacCQeMNdITUGXQxjA&s=09"
        target="_blank"
        rel="noopener noreferrer">
        <TwitterIconofWowMomos style={{ width: '30px', height: '30px' }} />
      </a>
      <a
        href="https://www.linkedin.com/company/13199845/admin/dashboard/"
        target="_blank"
        rel="noopener noreferrer">
        <LinkedInIconofWowMomos style={{ width: '33px', height: '30px' }} />
      </a>
    </$Flexbox>
  </React.Fragment>
);
