import React from 'react';
import { $SemiBold } from '../css/styles';

export const SemiBoldTag = ({
  label,
  value,
  paraStyle,
  htmlTag = 'p',
  isValueBold,
  width,
  isKeyBold = true,
}) => {
  const CustomTag = htmlTag;

  const getValue = () => {
    if (isValueBold) return <$SemiBold>{value}</$SemiBold>;
    return value;
  };

  if (value !== undefined && value !== null && value !== '')
    return (
      <CustomTag style={{ ...paraStyle, width }}>
        {isKeyBold ? <$SemiBold>{label} :</$SemiBold> : `${label} :`} {getValue()}
      </CustomTag>
    );

  return null;
};
