import React, { forwardRef } from 'react';
import { useTheme } from 'styled-components';
import { SocialTop, SocialBillMeBrands, F6Socials } from '../../social/Social';
import { BestSellerSocial } from '../../social/BestSellerSocial.jsx';
import { MarcaSocial } from '../../social/MarcaSocial';
import { DecathlonSocial } from '../../social/DecathlonSocial';
import { Le15Online, Le15Social } from '../../social/Le15Social';
import { TrentSocialList } from '../../social/TrentSocials';
import { DownloadBill } from '../../DownloadBill';
import AppleIcon from 'assets/images/apple-logo.svg';
import GplayIcon from 'assets/images/google-play-iconn.svg';
import TnCStaticContent from '../../footer/TnCStaticContent';
import {
  GlobalHTMLStyle,
  $Flexbox,
  $CineIndoDownloadBtn,
  $CineIndoFlexbox,
  $CineIndoTextA,
  $CineIndoTextB,
  $MirajButton,
  $PrasadsTnCContent,
  $TertiaryButton,
  MediaPrintStyle,
  $TertiaryButtonforGoColors,
  Dash,
} from '../../../css/styles';
import NeedHelp from '../../NeedHelp';
import { $NYBContent, $NYBContainer, $POBCloseButton } from '../../NeedHelp/NeedHelp.styled';
import CloseRedIcon from '../../../assets/images/close-red.svg';
import { MovieMaxIconsSet } from '../../social/MovieMaxSocial.jsx';
import { MenMomsIconsSet } from '../../social/MenMoms.jsx';
import { JoshhSocialTop } from 'components/social/JoshhRestaurant';
import { ValueSocial } from 'components/social/ValueSocial';
import { CCDSocial } from 'components/social/CCDSocial';
import { HaltSocial } from 'components/social/HaltSocial';
import { HastimalManikchandSocial } from 'components/social/HastimalManikchandSocial';
import { InventisSocial } from 'components/social/InventisSocial';
import { BeautynBeyondSocial } from 'components/social/BeautynBeyondSocial';
import CoussouqSocial from 'components/social/Coussouq';
import { ReactComponent as InstagramBW } from 'assets/images/instagram-logo-bw.svg';
import { $RRTerms } from '../../templates/styles';
import { VENDOR_META } from 'components/constants';
import { SocialGlobal } from 'components/social/SocialGlobal';

const TnCContent = (props) => {
  const {
    parsedData,
    isCinepolisGroup,
    isMovieMaxTicket,
    isRelaxo,
    isConnplex,
    isMiraj,
    isSamoh,
    isZudio,
    multiCoupons,
    isRoongta,
    isNyCinemas,
    isKaya,
    isBewakoof,
    isSuperK,
    isManyavar,
    isHouseofFett,
    isGoColors,
    brandName,
    brandLogo,
    isWowMomos,
    isRajhans,
  } = props;

  return (
    <TnCStaticContent
      parsedData={parsedData}
      multiCoupons={multiCoupons}
      isManyavar={isManyavar}
      isSuperK={isSuperK}
      isKaya={isKaya}
      isCinepolisGroup={isCinepolisGroup}
      isConnplex={isConnplex}
      isRoongta={isRoongta}
      isMovieMaxTicket={isMovieMaxTicket}
      isRelaxo={isRelaxo}
      isBewakoof={isBewakoof}
      isSamoh={isSamoh}
      isZudio={isZudio}
      isMiraj={isMiraj}
      isNyCinemas={isNyCinemas}
      isHouseofFett={isHouseofFett}
      isGoColors={isGoColors}
      brandName={brandName}
      brandLogo={brandLogo}
      isWowMomos={isWowMomos}
      isRajhans={isRajhans}
    />
  );
};

const SocialsTopRow = forwardRef((props, ref) => {
  const {
    flags,
    storeId,
    parsedData,
    isNYBModalOpen,
    setIsNYBModalOpen,
    handleRatingClick,
    feedbackExist,
    billUid,
    setNYBStatusFeedback,
    deletedBill,
    isTnCModalOpen,
    setIsTnCModalOpen,
    handleDownloadBillClick,
    isBkDownloadBillButtonDisabled,
    brandLogo,
    brandName,
  } = props;
  const {
    showSocial,
    isDecathlonIndiaEcom,
    isCCD,
    isMarcaEcomm,
    isBestSeller,
    isBillMeStore,
    isCentroGrande,
    isMiraj,
    hideFeedBack,
    isCinepolisGroup,
    isCinepolisTicket,
    isNYBRow,
    showTnCModal,
    isBK,
    isNykaaEcom,
    isLE15,
    isMovieMaxTicket,
    isMeNMoms,
    isDeluxCaterers,
    isValuePlusRetail,
    isRelaxo,
    isNuttysDen,
    isHalt,
    isCarnival,
    isInventis,
    isHastimalManikchand,
    isBeautynBeyond,
    isConnplex,
    isCoussouq,
    isF6Fashion,
    isF6FashionWomen,
    isZudio,
    isSamoh,
    isStarbazzar,
    isTrent,
    isMisbu,
    isRoongta,
    isBodyBuildingIndia,
    isHdfChargeSlip,
    isUtsa,
    isNyCinemas,
    isSuperK,
    isDemoWestside,
    isRzpPos,
    isDemoCinepolis,
    isDecathIndPlay,
    isKaya,
    isMulmul,
    isMahavirStore,
    isCinepolisIndonesia,
    isBewakoof,
    isManyavar,
    isWowMomos,
    isHouseofFett,
    isGoColors,
    isRzpFtx25,
    isZimson,
    isRajhans,
    isSammm,
  } = flags;
  const theme = useTheme();

  const multiCoupons = props?.coupons?.multiCoupons || null;

  const handleNYBModal = () => setIsNYBModalOpen(!isNYBModalOpen);
  const handleTnCModal = (e) => {
    e?.preventDefault();
    const params = new URLSearchParams(window.location.search);
    params.delete('showModal');
    const url = `${window.location.protocol}//${window.location.host}${window.location.pathname}${params?.toString()?.length ? '?' + params.toString() : ''}`;
    window.history.replaceState({}, document.title, url);
    setIsTnCModalOpen(!isTnCModalOpen);
  };

  const hideNotYourBill = isNuttysDen;

  const andriod = {
    superk:
      'https://play.google.com/store/apps/details?id=in.localbuy.superk.customer.superk_customer',
    cineIndo: 'https://play.google.com/store/apps/details?id=com.cinemaxx.activity&pli=1',
    tmrwBrands: 'https://play.google.com/store/apps/details?id=com.bewakoof.bewakoof&hl=en&pli=1',
  };
  const ios = {
    superk: 'https://apps.apple.com/us/app/superk/id6479734742',
    cineIndo: 'https://apps.apple.com/in/app/cin%C3%A9polis-cinemas-indonesia/id986733797',
    tmrwBrands: 'https://apps.apple.com/in/app/bewakoof-fashion-shopping-app/id1100190514',
  };

  const downloadAppBtnCineIndo = (type, flag) => {
    if (type === 'android') {
      window.open(andriod[flag], '_blank');
    }
    if (type === 'ios') {
      window.open(ios[flag], '_blank');
    }
  };

  const tertiaryButtonStyleforShowTnC = () => {
    return {
      ...(isCinepolisTicket ? { background: '#E7E7E8' } : {}),
      ...(isMiraj ? { background: 'white', fontWeight: 'bolder', color: 'black' } : {}),
      ...(isNyCinemas ? { fontWeight: 'bolder', color: '#013B49' } : {}),
      ...(isKaya ? { cursor: 'pointer', color: 'black', fontWeight: '600' } : {}),
      ...(isBewakoof ? { cursor: 'pointer', color: 'black', fontWeight: '600' } : {}),
    };
  };
  const tertiaryButtonStyle = () => {
    return {
      ...(isNykaaEcom ? { color: theme.color.nykaa } : {}),
      ...(isCinepolisTicket ? { background: '#E7E7E8' } : {}),
      ...(isMiraj ? { background: 'white', fontWeight: 'bolder', color: 'black' } : {}),
      ...(isNyCinemas ? { fontWeight: 'bolder', color: '#013B49', width: 'auto' } : {}),
      ...(isKaya ? { fontWeight: '600', color: 'black' } : {}),
      cursor: 'pointer',
    };
  };
  const tertiaryTmrwButtonStyle = () => {
    return {
      ...{ cursor: 'pointer', color: 'black', fontWeight: '600' },
    };
  };
  const renderNotYourBill = () => {
    return (
      <>
        {isNYBModalOpen && (
          <>
            <GlobalHTMLStyle />
            <NeedHelp
              onClose={handleNYBModal}
              openFeedback={handleRatingClick}
              readonlyFeedback={feedbackExist}
              hideFeedBack={hideFeedBack}
              billUid={billUid}
              setNYBStatus={setNYBStatusFeedback}
              isManyavar={isManyavar}
            />
          </>
        )}

        {isTnCModalOpen && (
          <>
            <GlobalHTMLStyle />
            <$NYBContainer>
              <$NYBContent>
                <$POBCloseButton onClick={handleTnCModal}>
                  <img src={CloseRedIcon} alr="Close button red colour" />
                </$POBCloseButton>
                <$PrasadsTnCContent revertStyle={isZudio || isSamoh}>
                  <TnCContent
                    isRelaxo={isRelaxo}
                    isCinepolisGroup={isCinepolisGroup}
                    isMovieMaxTicket={isMovieMaxTicket}
                    parsedData={parsedData}
                    isConnplex={isConnplex}
                    isMiraj={isMiraj}
                    isZudio={isZudio}
                    isSamoh={isSamoh}
                    multiCoupons={multiCoupons}
                    isRoongta={isRoongta}
                    isNyCinemas={isNyCinemas}
                    isKaya={isKaya}
                    isBewakoof={isBewakoof}
                    isSuperK={isSuperK}
                    isManyavar={isManyavar}
                    isHouseofFett={isHouseofFett}
                    isGoColors={isGoColors}
                    brandLogo={brandLogo}
                    brandName={brandName}
                    isWowMomos={isWowMomos}
                    isRajhans={isRajhans}
                  />
                </$PrasadsTnCContent>
              </$NYBContent>
            </$NYBContainer>
          </>
        )}
        {isConnplex && parsedData?.customData?.ticketType == 'CINEPOLIS_TICKET' && (
          <div>
            <section className="connplex-gst">
              {parsedData?.customData?.cin ? <p>CIN: {parsedData?.customData?.cin}</p> : null}
              <p>
                GSTIN:{' '}
                {`${parsedData?.customData?.gstin} ${
                  parsedData?.customData?.sac ? `/SAC: ${parsedData?.customData?.sac}` : ''
                }`}
              </p>
            </section>
          </div>
        )}

        {isConnplex && (
          <div>
            <h2 class="footerstyl"> #INDIA KA APNA SMART THEATRE </h2>
          </div>
        )}
        {isZimson && (
          <$Flexbox justify="center" width="auto" textAlign="center">
            <div>
              Thanks for shopping at Zimson. We truly appreciate your business and look forward to
              serving you again.
            </div>
          </$Flexbox>
        )}
        <$Flexbox
          width="auto"
          direction={isNYBRow || isDecathIndPlay || isMahavirStore ? 'row' : 'column'}
          alignItems="center"
          justify={isNYBRow || isDecathIndPlay || isMahavirStore ? 'space-between' : 'center'}
          {...(isRzpFtx25 || isNuttysDen
            ? { padding: '0' }
            : {
                padding: '16px',
              })}>
          {!isConnplex && showTnCModal && !isKaya && !isSuperK && (
            <$TertiaryButton
              style={tertiaryButtonStyleforShowTnC()}
              isWhiteColor={isCarnival}
              className={`${isManyavar ? 'manyavarTnC' : isGoColors ? 'GoColorsTnC' : isWowMomos ? 'wowmomosTnc' : ''}`}
              onClick={handleTnCModal}>
              Terms and Conditions
            </$TertiaryButton>
          )}
          {isSuperK && (
            <$TertiaryButton className="footerbtntc" style={tertiaryButtonStyleforShowTnC()}>
              <a
                style={{ color: 'black', cursor: 'pointer' }}
                href="https://www.superk.in/termsofservice"
                target="_blank">
                Terms and Conditions
              </a>
            </$TertiaryButton>
          )}
          {isConnplex && (
            <$TertiaryButton className="footerbtntc" onClick={handleTnCModal}>
              Terms and Conditions
            </$TertiaryButton>
          )}
          {isKaya && (
            <a href="?showModal=tnc">
              <$TertiaryButton style={tertiaryButtonStyleforShowTnC()} onClick={handleTnCModal}>
                Terms & Conditions
              </$TertiaryButton>
            </a>
          )}
          {(isBK ||
            isDecathlonIndiaEcom ||
            isHalt ||
            isBodyBuildingIndia ||
            isDecathIndPlay ||
            isMahavirStore) && (
            <>
              <MediaPrintStyle />
              <$TertiaryButton
                onClick={handleDownloadBillClick}
                className="media-no-print"
                disabled={isBkDownloadBillButtonDisabled}
                style={{ cursor: 'pointer' }}>
                Download Bill
              </$TertiaryButton>
            </>
          )}

          {!hideNotYourBill && !isConnplex && !isRzpPos && !isRzpFtx25 ? (
            isHdfChargeSlip ? (
              <$RRTerms>
                <a rel="noopener" target="_blank" href="">
                  {' '}
                  Terms and Conditions
                </a>
              </$RRTerms>
            ) : (
              <$TertiaryButton
                isWhiteColor={isCarnival}
                onClick={deletedBill ? () => {} : handleNYBModal}
                className={`media-no-print ${isManyavar ? 'manyavarTnC' : isGoColors ? 'GoColorsTnC' : isWowMomos ? 'wowmomosTnc' : ''}`}
                style={isBewakoof ? tertiaryTmrwButtonStyle() : tertiaryButtonStyle()}>
                {isNyCinemas
                  ? ' Not your ticket? Click here'
                  : isSuperK || isKaya || isBewakoof
                    ? ' Not your bill? Click here '
                    : 'Need help? Click here'}
              </$TertiaryButton>
            )
          ) : null}
          {isLE15 ? (
            <>
              <Le15Social />
              <Le15Online />
            </>
          ) : null}
        </$Flexbox>

        {isGoColors && (
          <>
            <MediaPrintStyle />
            <$TertiaryButtonforGoColors
              onClick={handleDownloadBillClick}
              className="media-no-print"
              disabled={isBkDownloadBillButtonDisabled}
              style={{ cursor: 'pointer' }}>
              Download Bill
            </$TertiaryButtonforGoColors>
          </>
        )}

        {isCinepolisIndonesia ||
          isSuperK ||
          (isBewakoof && (
            <$CineIndoFlexbox>
              <$CineIndoDownloadBtn
                width="auto"
                onClick={() =>
                  downloadAppBtnCineIndo(
                    'android',
                    isCinepolisIndonesia ? 'cineIndo' : isSuperK ? 'superk' : 'tmrwBrands',
                  )
                }
                className="media-no-print"
                style={{
                  ...tertiaryButtonStyle(),
                  width: '200px',
                  height: '50px',
                  backgroundColor: '#000000',
                }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    style={{ width: '48px', height: '30px' }}
                    src={GplayIcon}
                    alt="Google Play icon"
                  />
                  <div style={{ textAlign: 'left' }}>
                    <$CineIndoTextA>GET IT ON</$CineIndoTextA>
                    <br />
                    <$CineIndoTextB>
                      <span>Google Play</span>
                    </$CineIndoTextB>
                  </div>
                </div>
              </$CineIndoDownloadBtn>
              <$CineIndoDownloadBtn
                width="auto"
                onClick={() =>
                  downloadAppBtnCineIndo(
                    'ios',
                    isCinepolisIndonesia ? 'cineIndo' : isSuperK ? 'superk' : 'tmrwBrands',
                  )
                }
                className="media-no-print"
                style={{
                  ...tertiaryButtonStyle(),
                  width: '200px',
                  height: '50px',
                  backgroundColor: '#000000',
                }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img style={{ width: '50px', height: '30px' }} src={AppleIcon} alt="Apple Icon" />
                  <div style={{ textAlign: 'left' }}>
                    <$CineIndoTextA>Download on the</$CineIndoTextA> <br />
                    <$CineIndoTextB>
                      <span>App store</span>
                    </$CineIndoTextB>
                  </div>
                </div>
              </$CineIndoDownloadBtn>
            </$CineIndoFlexbox>
          ))}
        {isConnplex ? (
          <$TertiaryButton
            onClick={deletedBill ? () => {} : handleNYBModal}
            className="footerbtnnh">
            Need help? Click here
          </$TertiaryButton>
        ) : null}
      </>
    );
  };

  return (
    <>
      {isNykaaEcom && (
        <DownloadBill
          ref={ref}
          customData={parsedData}
          handleDownloadBillClick={handleDownloadBillClick}
        />
      )}
      {renderNotYourBill()}
      {isZudio && <div style={{ textAlign: 'center' }}>Love what's in? Follow to know more</div>}
      {isSammm && <div style={{ textAlign: 'center' }}>Join The Sammmm Squad</div>}
      {isCoussouq && <CoussouqSocial />}
      {isDeluxCaterers && <JoshhSocialTop />}
      {isValuePlusRetail && <ValueSocial />}
      {showSocial && <SocialTop isCentroGrande={isCentroGrande} />}
      {isDecathlonIndiaEcom && <DecathlonSocial />}
      {isHalt && <HaltSocial />}
      {isCCD && <CCDSocial />}
      {isMarcaEcomm && <MarcaSocial />}
      {isBestSeller && <BestSellerSocial />}
      {isBillMeStore && <SocialBillMeBrands storeId={storeId} />}
      {isMovieMaxTicket && <MovieMaxIconsSet />}
      {isMeNMoms && <MenMomsIconsSet />}
      {isHastimalManikchand && <HastimalManikchandSocial />}
      {isInventis && <InventisSocial />}
      {isBeautynBeyond && <BeautynBeyondSocial />}
      {isF6Fashion && <F6Socials isF6FashionWomen={isF6FashionWomen} />}
      <TrentSocialList
        isSamoh={isSamoh}
        isZudio={isZudio}
        isMisbu={isMisbu}
        isStarbazzar={isStarbazzar}
      />
      {isDemoCinepolis ? <SocialGlobal template={'demoCinepolis'} /> : null}
      {isDemoWestside ? <SocialGlobal template={'westside'} /> : null}
      {isMulmul ? <SocialGlobal template={'mulmul'} /> : null}
    </>
  );
});

export default SocialsTopRow;
