import React, { forwardRef, lazy } from 'react';
import { SuspenseLoading } from '../../SuspenseLoading';
import { $RRTerms, BillImage } from '../../templates/styles';
import RZPBMLogoLight from '../../../assets/images/logos/rzpbm-logo-Light.svg';
import styled from 'styled-components';

const McDonalds = lazy(() => import('../../templates/McDonalds'));
const Esbeda = lazy(() => import('../../templates/Esbeda'));
const Dinapala = lazy(() => import('../../templates/Dinapala'));
const BurgerKing = lazy(() => import('../../templates/BurgerKing'));
const Decathlon = lazy(() => import('../../templates/Decathlon'));
const Pdf = lazy(() => import('../../templates/PdfTemplate'));
const CinepolisTicket = lazy(() => import('../../templates/CinepolisNormalTicket'));
const RzpFtx25 = lazy(() => import('../../templates/RzpFtx25'));
const CinepolisIndonesiaMovieTicket = lazy(
  () => import('../../templates/CinepolisIndonesiaMovieTicket'),
);
const CinnepolisIndonesiaFood = lazy(() => import('../../templates/CinnepolisIndonesiaFoodTicket'));
const FunCinemasTicket = lazy(() => import('../../templates/FunCinemas'));
const MirajTicket = lazy(() => import('../../templates/MirajTicket'));
const MirajFood = lazy(() => import('../../templates/MirajFood'));
const CinepolisFood = lazy(() => import('../../templates/CinepolisFood'));
const ConnplexMovie = lazy(() => import('../../templates/ConnplexMovie'));
const ConnplexFood = lazy(() => import('../../templates/ConnplexFood'));
const HdfcChargeSlip = lazy(() => import('../../templates/Hdfc'));
const DecathlongIndiaEcom = lazy(() => import('../../templates/DecathlongIndiaEcom'));
const BeautynBeyond = lazy(() => import('../../templates/BeautynBeyond'));
const MarcaEcomm = lazy(() => import('../../templates/MarcaDisati'));
const Prasads = lazy(() => import('../../templates/Prasads'));
const NykaaEcomm = lazy(() => import('../../templates/NykaaEcom'));
const BestSellerEcom = lazy(() => import('../../templates/BestSellerEcom'));
const Veltosa = lazy(() => import('../../templates/Veltosa'));
const DefaultEcom = lazy(() => import('../../templates/DefaultEcom'));
const DefaultEcomV2 = lazy(() => import('../../templates/DefaultEcomV2'));
const RelaxoEcom = lazy(() => import('../../templates/RelaxoEcom'));
const Bewakoof = lazy(() => import('../../templates/Bewakoof'));
const GoColors = lazy(() => import('../../templates/GoColors'));
const TWCEcom = lazy(() => import('../../templates/TWCEcom'));
const EcomThirdPartyPlatform = lazy(() => import('../../templates/EcomThirdPartyPlatform'));
const Gadchiroli = lazy(() => import('../../templates/Gadchiroli')); // now being used as a template for City-Of-Patna
const CityOfPatna = lazy(() => import('../../templates/CityOfPatna')); // now being used as a template for City-Of-Patna
const RawMango = lazy(() => import('../../templates/RawMango'));
const SPRVL = lazy(() => import('../../templates/SPRVLEcom'));
const NyCinemas = lazy(() => import('../../templates/NyCinemas'));
const TrentDefaultBill = lazy(() => import('../../templates/TrentDefaultEcom'));
const SuperK = lazy(() => import('../../templates/SuperK'));
const KayaBeauty = lazy(() => import('../../templates/Kaya'));
const DecathlongIndiaPlayEcom = lazy(() => import('../../templates/DecathlongIndiaPlayEcom'));
const Manyavar = lazy(() => import('../../templates/Manyavar'));

const CenteredContainer = styled.div`
  text-align: center;
  height: 2vh;
`;

const CenteredImg = styled.img`
  display: inline-block;
  max-width: 35%;
`;

const BillTemplate = forwardRef((props, billTemplateRef) => {
  const {
    flags,
    parsedData,
    other,
    billImageUrl,
    brandName,
    billUid,
    adjustBillHeight,
    size,
    storeAddress,
    multiCoupons,
    feedbackElem,
    storeId,
    brandLogo,
    displayAddress,
    createdDate,
    isPaymentStatus,
  } = props;
  const {
    isMiraj,
    isFunCinemas,
    isBK,
    isDecathlonTemplate,
    isBkEcomm,
    isDecathlonIndiaEcom,
    isNykaaEcom,
    isBestSeller,
    isDinapala,
    isEsbeda,
    isMarcaEcomm,
    isPrasads,
    isVeltosa,
    isRRGold,
    isPdf,
    isEcomBill,
    isRelaxo,
    isEcomThirdParty,
    isMcDonaldsTemplate,
    isCinepolisTicket,
    isCinepolisGroup,
    isCinepolisIndonesia,
    isMovieMaxTicket,
    isFoodBill,
    isDeluxCaterers,
    isCarnival,
    isBeautynBeyond,
    isStylyn,
    isGadchiroli,
    isCityOfPatna,
    isCinemaBill,
    isShilpiBill,
    isRawMango,
    isSPRVL,
    isTWC,
    isTrent,
    isHdfChargeSlip,
    appendCompAddressToStoreAddress,
    appendStoreCodeToInvoice,
    showTotalSavingsTop,
    isConnplex,
    isZudio,
    isUtsa,
    isRajhans,
    isNyCinemas,
    isRzpPos,
    isDecathIndPlay,
    isDefaultEcomV2,
    isTrentBrands,
    isSuperK,
    isKaya,
    isWestside,
    isBewakoof,
    isStarbazzar,
    isTrentHyperMarket,
    isManyavar,
    isWowMomos,
    isFoodOrderLinkforRajhans,
    isGoColors,
    isWonderSoft,
    isChineseWok,
    isRzpFtx25,
    isKpn,
    isCineport,
    isMiArcus,
    isSammm,
  } = flags;

  function renderPdfTemplate(isRRGold = false) {
    return (
      <Pdf
        url={billImageUrl}
        size={size}
        adjustHeight={adjustBillHeight}
        billUid={billUid}
        isRRGold={isRRGold}
      />
    );
  }
  if (isConnplex)
    return (
      <React.Suspense fallback={<SuspenseLoading />}>
        {parsedData?.customData?.ticketType === 'CINEPOLIS_TICKET' ? (
          <ConnplexMovie
            ref={billTemplateRef}
            isFunCinemas={isFunCinemas}
            isCarnival={isCarnival}
            customData={parsedData?.customData}
            storeAddress={storeAddress}
            multiCoupons={multiCoupons}
            storeId={storeId}
          />
        ) : (
          <ConnplexFood customData={parsedData?.customData} />
        )}
      </React.Suspense>
    );
  else if (isDefaultEcomV2) {
    return (
      <React.Suspense fallback={<SuspenseLoading />}>
        <DefaultEcomV2
          isTrent={isTrent}
          appendCompAddressToStoreAddress={appendCompAddressToStoreAddress}
          appendStoreCodeToInvoice={appendStoreCodeToInvoice}
          showTotalSavingsTop={showTotalSavingsTop}
          isDeluxCaterers={isDeluxCaterers}
          ref={billTemplateRef}
          customData={parsedData}
          event={parsedData?.event}
          other={other}
          isZudio={isZudio}
          isUtsa={isUtsa}
          isRzpPos={isRzpPos}
          storeAddress={storeAddress}
          isChineseWok={isChineseWok}
          isKpn={isKpn}
        />
      </React.Suspense>
    );
  } else if (isCinepolisIndonesia)
    return (
      <React.Suspense fallback={<SuspenseLoading />}>
        {parsedData?.customData?.ticketType === 'CINEPOLIS_FOOD' ? (
          <CinnepolisIndonesiaFood
            ref={billTemplateRef}
            isFunCinemas={isFunCinemas}
            isCarnival={isCarnival}
            customData={parsedData?.customData}
            storeAddress={storeAddress}
            isRajhans={isRajhans}
            isNyCinemas={isNyCinemas}
          />
        ) : (
          <CinepolisIndonesiaMovieTicket
            isMovieMax={isMovieMaxTicket || isShilpiBill}
            ref={billTemplateRef}
            customData={parsedData?.customData}
            storeAddress={storeAddress}
            createdDate={createdDate}
            multiCoupons={multiCoupons}
            isRajhans={isRajhans}
            displayAddress={displayAddress}
          />
        )}
      </React.Suspense>
    );
  else if (isMiraj || isCarnival)
    return (
      <React.Suspense fallback={<SuspenseLoading />}>
        {parsedData?.customData?.ticketType === 'CINEPOLIS_FOOD' ? (
          <MirajFood
            ref={billTemplateRef}
            isFunCinemas={isFunCinemas}
            isCarnival={isCarnival}
            customData={parsedData?.customData}
            storeAddress={storeAddress}
            isRajhans={isRajhans}
            isNyCinemas={isNyCinemas}
          />
        ) : (
          <MirajTicket
            ref={billTemplateRef}
            isFunCinemas={isFunCinemas}
            isCarnival={isCarnival}
            customData={parsedData?.customData}
            storeAddress={storeAddress}
            multiCoupons={multiCoupons}
            storeId={storeId}
            isMiraj={isMiraj}
            isRajhans={isRajhans}
          />
        )}
      </React.Suspense>
    );
  else if (isRzpFtx25)
    return (
      <React.Suspense fallback={<SuspenseLoading />}>
        <RzpFtx25 feedbackElem={feedbackElem} ref={billTemplateRef} customData={parsedData} />
      </React.Suspense>
    );
  else if (isCinepolisTicket)
    return (
      <React.Suspense fallback={<SuspenseLoading />}>
        {isFunCinemas ? (
          <FunCinemasTicket
            feedbackElem={feedbackElem}
            ref={billTemplateRef}
            isFunCinemas={isFunCinemas}
            customData={parsedData?.customData}
          />
        ) : (
          <CinepolisTicket
            feedbackElem={feedbackElem}
            ref={billTemplateRef}
            isFunCinemas={isFunCinemas}
            customData={parsedData?.customData}
          />
        )}
      </React.Suspense>
    );
  else if (isNyCinemas && parsedData?.customData?.ticketType === 'CINEPOLIS_TICKET') {
    return (
      <React.Suspense fallback={<SuspenseLoading />}>
        <NyCinemas
          ref={billTemplateRef}
          customData={parsedData?.customData}
          storeAddress={storeAddress}
          multiCoupons={multiCoupons}
        />
      </React.Suspense>
    );
  } else if (
    (isCinepolisGroup || isMiraj) &&
    parsedData?.customData?.ticketType === 'CINEPOLIS_FOOD'
  )
    return (
      <React.Suspense fallback={<SuspenseLoading />}>
        <CinepolisFood customData={parsedData?.customData} />
      </React.Suspense>
    );
  else if (isBK)
    return (
      <React.Suspense fallback={<SuspenseLoading />}>
        <BurgerKing
          ref={billTemplateRef}
          parsedData={parsedData}
          isBkEcomm={isBkEcomm}
          ecommData={parsedData}
        />
      </React.Suspense>
    );
  else if (isSuperK)
    return (
      <React.Suspense fallback={<SuspenseLoading />}>
        <SuperK ref={billTemplateRef} customData={parsedData} />
      </React.Suspense>
    );
  else if (isDecathlonTemplate)
    return (
      <React.Suspense fallback={<SuspenseLoading />}>
        <Decathlon ref={billTemplateRef} customData={parsedData?.customData} />
      </React.Suspense>
    );
  else if (isBeautynBeyond)
    return (
      <React.Suspense fallback={<SuspenseLoading />}>
        <BeautynBeyond ref={billTemplateRef} customData={parsedData} />
      </React.Suspense>
    );
  else if (isDecathlonIndiaEcom)
    return (
      <React.Suspense fallback={<SuspenseLoading />}>
        <DecathlongIndiaEcom ref={billTemplateRef} customData={parsedData} />
      </React.Suspense>
    );
  else if (isDecathIndPlay)
    return (
      <React.Suspense fallback={<SuspenseLoading />}>
        <DecathlongIndiaPlayEcom ref={billTemplateRef} customData={parsedData} />
      </React.Suspense>
    );
  else if (isNykaaEcom)
    return (
      <React.Suspense fallback={<SuspenseLoading />}>
        <NykaaEcomm ref={billTemplateRef} customData={parsedData} />
      </React.Suspense>
    );
  else if (isBestSeller)
    return (
      <React.Suspense fallback={<SuspenseLoading />}>
        <BestSellerEcom ref={billTemplateRef} customData={parsedData} />
      </React.Suspense>
    );
  else if (isDinapala)
    return (
      <React.Suspense fallback={<SuspenseLoading />}>
        <Dinapala ref={billTemplateRef} customData={parsedData} />
      </React.Suspense>
    );
  else if (isEsbeda)
    return (
      <React.Suspense fallback={<SuspenseLoading />}>
        <Esbeda ref={billTemplateRef} customData={parsedData} />
      </React.Suspense>
    );
  else if (isMarcaEcomm)
    return (
      <React.Suspense fallback={<SuspenseLoading />}>
        <MarcaEcomm ref={billTemplateRef} customData={parsedData} />
      </React.Suspense>
    );
  else if (isPrasads)
    return (
      <React.Suspense fallback={<SuspenseLoading />}>
        <Prasads ref={billTemplateRef} customData={parsedData.customData} />
      </React.Suspense>
    );
  else if (isVeltosa)
    return (
      <React.Suspense fallback={<SuspenseLoading />}>
        <Veltosa ref={billTemplateRef} customData={parsedData} />
      </React.Suspense>
    );
  else if (isMcDonaldsTemplate)
    return (
      <React.Suspense fallback={<SuspenseLoading />}>
        <McDonalds ref={billTemplateRef} parsedData={parsedData} />
      </React.Suspense>
    );
  else if (isKaya)
    return (
      <React.Suspense fallback={<SuspenseLoading />}>
        <KayaBeauty ref={billTemplateRef} customData={parsedData} />
      </React.Suspense>
    );
  else if (isBewakoof)
    return (
      <React.Suspense fallback={<SuspenseLoading />}>
        <Bewakoof ref={billTemplateRef} customData={parsedData} />
      </React.Suspense>
    );
  else if (isGoColors)
    return (
      <React.Suspense fallback={<SuspenseLoading />}>
        <GoColors ref={billTemplateRef} customData={parsedData} other={other} />
      </React.Suspense>
    );
  else if (isManyavar)
    return (
      <React.Suspense fallback={<SuspenseLoading />}>
        <Manyavar
          ref={billTemplateRef}
          customData={parsedData}
          handleClick={props.onClick}
          showTaxDetails={props.showTaxDetails}
        />
      </React.Suspense>
    );
  else if (isHdfChargeSlip)
    return (
      <React.Suspense fallback={<SuspenseLoading />}>
        <h3 style={{ textAlign: 'center', fontSize: '13px' }}>{parsedData?.customData?.address}</h3>
        <HdfcChargeSlip ref={billTemplateRef} customData={parsedData?.customData} />
        <CenteredContainer>
          <CenteredImg src={RZPBMLogoLight} alt="BillMe Logo" />
        </CenteredContainer>
      </React.Suspense>
    );
  else if (isRRGold)
    return (
      <React.Suspense fallback={<SuspenseLoading />}>
        {/* <$RRGoldHeader /> Temporarily Commented */}
        {renderPdfTemplate(true)}
        {/* <$RRGoldFooter /> */}
        <$RRTerms>
          <a
            rel="noopener"
            target="_blank"
            href="https://assets.billme.co.in/public/RR_Back-converted.pdf">
            {' '}
            Terms and Conditions
          </a>
          <a rel="noopener" target="_blank" href="https://wa.me/+918183900090">
            {' '}
            <span /> +91 8183900090{' '}
          </a>
        </$RRTerms>
      </React.Suspense>
    );
  else if (isEcomThirdParty || brandName === 'bm-store-testing')
    return (
      <React.Suspense fallback={<SuspenseLoading />}>
        <EcomThirdPartyPlatform
          ref={billTemplateRef}
          customData={parsedData}
          isStylyn={isStylyn}
          isPaymentStatus={isPaymentStatus}
          isMiArcus={isMiArcus}
          isSammm={isSammm}
        />
      </React.Suspense>
    );
  else if (isCinemaBill)
    if (isConnplex) {
      return (
        <React.Suspense fallback={<SuspenseLoading />}>
          {isFoodBill ? (
            <></>
          ) : (
            <ConnplexMovie
              isMovieMax={isMovieMaxTicket || isShilpiBill}
              ref={billTemplateRef}
              customData={parsedData?.customData}
              storeAddress={storeAddress}
              multiCoupons={multiCoupons}
            />
          )}
        </React.Suspense>
      );
    } else {
      return (
        <React.Suspense fallback={<SuspenseLoading />}>
          {isFoodBill ? (
            <MirajFood
              isMovieMax={isMovieMaxTicket || isShilpiBill}
              ref={billTemplateRef}
              customData={parsedData?.customData}
              isRajhans={isRajhans}
              isNyCinemas={isNyCinemas}
            />
          ) : (
            <MirajTicket
              isMovieMax={isMovieMaxTicket || isShilpiBill}
              ref={billTemplateRef}
              customData={parsedData?.customData}
              storeAddress={storeAddress}
              multiCoupons={multiCoupons}
              isRajhans={isRajhans}
              other={other}
              isCineport={isCineport}
              isFoodOrderLinkforRajhans={isFoodOrderLinkforRajhans}
            />
          )}
        </React.Suspense>
      );
    }
  else if (isCityOfPatna)
    return (
      <React.Suspense fallback={<SuspenseLoading />}>
        <CityOfPatna ref={billTemplateRef} customData={parsedData} />
      </React.Suspense>
    );
  else if (isGadchiroli)
    return (
      <React.Suspense fallback={<SuspenseLoading />}>
        <Gadchiroli ref={billTemplateRef} customData={parsedData} />
      </React.Suspense>
    );
  else if (isRawMango) {
    return (
      <React.Suspense fallback={<SuspenseLoading />}>
        <RawMango
          ref={billTemplateRef}
          customData={parsedData?.customData}
          storeAddress={storeAddress}
        />
      </React.Suspense>
    );
  } else if (isRelaxo && isEcomBill)
    return (
      <React.Suspense fallback={<SuspenseLoading />}>
        <RelaxoEcom ref={billTemplateRef} customData={parsedData} />
      </React.Suspense>
    );
  else if (isTWC && isEcomBill)
    return (
      <React.Suspense fallback={<SuspenseLoading />}>
        <TWCEcom ref={billTemplateRef} customData={parsedData} storeAddress={storeAddress} />
      </React.Suspense>
    );
  else if (isSPRVL && isEcomBill)
    return (
      <React.Suspense fallback={<SuspenseLoading />}>
        <SPRVL ref={billTemplateRef} customData={parsedData} />
      </React.Suspense>
    );
  else if (isTrentBrands)
    return (
      <React.Suspense fallback={<SuspenseLoading />}>
        <TrentDefaultBill
          isTrent={isTrent}
          appendCompAddressToStoreAddress={appendCompAddressToStoreAddress}
          appendStoreCodeToInvoice={appendStoreCodeToInvoice}
          showTotalSavingsTop={showTotalSavingsTop}
          isDeluxCaterers={isDeluxCaterers}
          ref={billTemplateRef}
          customData={parsedData}
          isUtsa={isUtsa}
          isWestside={isWestside}
          isStarbazzar={isStarbazzar}
          isZudio={isZudio}
          isTrentHyperMarket={isTrentHyperMarket}
        />
      </React.Suspense>
    );
  else if (isEcomBill)
    return (
      <React.Suspense fallback={<SuspenseLoading />}>
        <DefaultEcom
          isTrent={isTrent}
          appendCompAddressToStoreAddress={appendCompAddressToStoreAddress}
          appendStoreCodeToInvoice={appendStoreCodeToInvoice}
          showTotalSavingsTop={showTotalSavingsTop}
          isDeluxCaterers={isDeluxCaterers}
          ref={billTemplateRef}
          customData={parsedData}
          other={other}
          isZudio={isZudio}
          isUtsa={isUtsa}
          isRzpPos={isRzpPos}
          isWestside={isWestside}
          isWonderSoft={isWonderSoft}
        />
      </React.Suspense>
    );
  else if (billImageUrl && isPdf)
    return <React.Suspense fallback={<SuspenseLoading />}>{renderPdfTemplate()}</React.Suspense>;
  else if (billImageUrl) return <BillImage src={billImageUrl} />;
});

export default BillTemplate;
