import styled, { css } from 'styled-components';
import { BillMaxWidth } from '../../components/constants';

const ActionButton = styled.button`
  border-radius: 5px;
  height: 48px;
  width: 300px;
  font-size: 17px;
  cursor: pointer;
`;

export const PrimaryButton = styled(ActionButton)`
  background: #70a400 0% 0% no-repeat padding-box;
  color: #fff;
  border: 1px solid #2e6da4;
  margin-bottom: 20px;
`;

export const SecondaryButton = styled(ActionButton)`
  background-color: hsl(216, 13%, 92%);
  border: 1px solid #e9ebee;
  color: black;
  margin-bottom: 20px;

  &:hover {
    background-color: hsl(216, 13%, 85%);
  }
`;

export const CloseButton = styled.button`
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
  float: right;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  color: #f0f0f0;
  filter: alpha(opacity=20);
  position: absolute;
  top: 10px;
  right: 10px;

  &:hover {
    color: #ccc;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: relative;
`;

export const Content = styled.div`
  text-align: center;
  width: 600px;
  padding: 50px 0px;
  min-height: 600px;
  box-shadow: 2px 25px 29px 0px #ccc;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;

  @media screen and (max-width: 480px) {
    box-shadow: 2px 25px 29px 0px #fff;
  }

  form {
    margin: 0px auto;
    display: table;
  }

  div#billme-branding {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      margin-bottom: 5px;
    }

    p {
      color: hsl(0, 0%, 60%);
      margin: 0px;
      font-size: 14px;
      font-family: Roboto;
    }
  }
`;

export const EmailBar = styled.div`
  label {
    width: 300px;
    color: #606060;
    display: inline-block;
    max-width: 100%;
    margin-bottom: 10px;
    font-weight: 700;
    position: relative;
    left: 0px;
  }

  #email {
    width: 300px;
    height: 43px;
    font-size: 18px;
    display: block;
    padding: 6px 12px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
`;

export const Modal = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: hsla(0, 0%, 95%);
  position: fixed;
  top: 0px;
  display: flex;
  justify-content: center;
  overflow-y: auto;
`;

export const CloseBillContainer = styled.div`
  display: flex;
  justify-content: center;

  button {
    height: 48px;
    width: 300px;
    color: #333;
    background-color: #fff;
    border-color: #ccc;
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 17px;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 4px;
    border-color: #ccc;

    &:hover {
      background-color: #e6e6e6;
      border-color: #adadad;
    }
  }
`;

export const ErrorMessage = styled.p`
  margin: 0px;
  font-size: 14px;
  color: red;
`;

export const MirajMoreButton = styled.button.attrs((_) => ({
  type: 'button',
}))`
  border: none;
  background: rgba(0, 0, 0, 0.2);
  color: ${({ isCarnival }) => (isCarnival ? '#e80000' : '#c17e44')};
  padding: 4px 10px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  outline: none;
  border: 1px solid transparent;
  margin-top: 4px;

  &:focus,
  &:active {
    border: 1px solid #000;
  }
`;

export const RajhansText = styled.p`
  font-family: 'Roboto-bold', sans-serif;
  font-size: 18px;
  font-weight: 600;
  text-align: ${(props) => props.textAlign};
`;

export const RajhansTextA = styled.p`
  font-family: 'Roboto-bold', sans-serif;
  font-size: 16px;
`;

export const MoreButton = styled.button.attrs((_) => ({
  type: 'button',
}))`
  border: none;
  background: hsl(203, 59%, 95%);
  color: hsl(203, 55%, 55%);
  padding: 4px 10px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  outline: none;
  border: 1px solid transparent;
  margin-top: 4px;

  &:focus,
  &:active {
    border: 1px solid hsl(203, 55%, 55%);
  }
`;

export const TruncateText = styled.span`
  display: inline-block;
  white-space: ${(props) => (props.normalWrap ? 'normal' : 'nowrap')};
  overflow: ${(props) => (props.visible ? 'visible' : 'hidden')};
  text-overflow: ellipsis;
  margin-bottom: 0;
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth || '100%'};
`;

export const ToggleBillHeight = styled.button`
  border: none;
  border-radius: ${({ isBK }) => (isBK ? 0 : '0px 0px 20px 20px')};
  width: ${({ isManyavar, isGoColors, isRzpFtx25, isWowMomos }) =>
    isRzpFtx25 || isManyavar || isGoColors || isWowMomos ? '100%' : '94%'};
  margin: ${({ isManyavar, isGoColors, isRzpFtx25, isWowMomos }) =>
    isRzpFtx25 || isManyavar || isGoColors || isWowMomos ? '0px' : '0px 3%'};
  padding: 10px 0px;
  font-size: 0.8em;
  outline: none;

  ${({ isBK }) =>
    isBK &&
    css`
      font-family: 'Block-Berthold', sans-serif;
      text-transform: uppercase;
      &:hover,
      &:active,
      &:focus {
        background: #6d3d2f;
      }
    `}

  ${({ isNewBillView, color }) =>
    isNewBillView
      ? css`
          background: ${({ isBK }) => (isBK ? '#6D3D2F' : '#fff')};
          border-radius: 0px;
          color: ${color};
        `
      : css`
          background: ${({ isBK }) =>
            isBK ? '#6D3D2F' : 'linear-gradient(#38A1E3 80%,hsl(203, 61%, 40%) 110%)'};
          color: #fff !important;
        `};

  ${({ isBestSeller }) =>
    isBestSeller &&
    css`
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 500;
      color: #4e6466;
    `}

  ${({ isCinepolisGroup }) =>
    isCinepolisGroup &&
    css`
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 500;
      background: #112657;
    `}

    ${({ isMiraj }) =>
    isMiraj &&
    css`
      width: 100%;
      margin: 0 0 0 0;
      text-transform: uppercase;
      font-weight: 500;
      background: #fff;
      border-radius: 0;
      color: #c17e44 !important;
    `}

    ${({ isCarnival }) =>
    isCarnival &&
    css`
      width: 100%;
      margin: 0;
      font-weight: 500;
      background: #fff;
      border-radius: 0;
      color: #e80000 !important;
    `}
    
    @media screen and (max-width: 767px) {
    font-size: 1em;
    padding: 15px 0px;
  }
`;

export const SerifText = styled.span`
  font-family: 'Times New Roman', Times, serif;
`;

export const $GreyText = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.color.doveGray};
`;
export const $TertiaryButtonforGoColors = styled.button.attrs(() => ({
  type: 'button',
}))`
  cursor: pointer;
  background-color: black;
  font-size: 13px;
  color: white;
  padding: 9px 20px;
  text-align: center;
  margin: 20px;
  width: 91%;
  border-radius: 10px;
`;

export const $TertiaryButton = styled.button.attrs(() => ({
  type: 'button',
}))`
  margin: 8px 0px;
  padding: 4px 16px;
  font-size: 12px;
  border-radius: 8px;
  border: none;
  color: #393939;
  background-color: white;
  text-decoration: underline;

  ${({ isWhiteColor }) =>
    isWhiteColor &&
    css`
      font-size: 14px;
      background-color: black;
      color: white;
      font-weight: 700;
      padding: 4px 8px;
    `}
`;

export const $MirajButton = styled.button.attrs(() => ({
  type: 'button',
}))`
  margin: 8px 0px;
  padding: 4px;
  font-size: 14px;
  border-radius: 8px;
  border: none;
  color: #c17e44;
  cursor: pointer;
  background-color: #000;
  font-weight: 600;
`;

export const $Flexbox = styled.div`
  display: flex;
  width: ${({ width }) => width || '100%'};
  height: calc(100% - 42px);
  flex-direction: ${({ direction }) => direction || 'row'};
  justify-content: ${({ justify }) => justify || 'flex-start'};
  align-items: ${({ alignItems }) => alignItems || 'flex-start'};
  gap: ${({ gap }) => gap || '0px'};
  padding: ${({ padding }) => padding || '16px'};
  ${(props) => props.color && `color: ${props.color};`}
  ${(props) => props.textAlign && `text-align: ${props.textAlign};`}
`;
export const $CineIndoDownloadBtn = styled.button.attrs(() => ({
  type: 'button',
}))`
  font-size: 14px;
  border-radius: 15px;
  border: 1px solid black;
  color: white;
  cursor: pointer;
  background-color: rgb(8, 40, 91);
  padding: 8px;
  flex: 1;
  max-width: 200px;
`;
export const $CineIndoFlexbox = styled.div`
  display: flex;
  padding: 16px;
  width: ${({ width }) => width || 'auto'};
  height: calc(100% - 42px);
  flex-direction: ${({ direction }) => direction || 'row'};
  justify-content: ${({ justify }) => justify || 'center'};
  align-items: ${({ alignItems }) => alignItems || 'flex-start'};
  flex-wrap: wrap;
  column-gap: ${({ colGap }) => colGap || '25px'};
  row-gap: ${({ colGap }) => colGap || '15px'};
  ${(props) =>
    props.color &&
    `color: ${props.color};
    width: 100%, 
    maxWidth: 400px`}
`;
export const $CineIndoTextA = styled.div`
  font-family: Montserrat;
  font-size: 7px;
  margin-bottom: -5px;
`;
export const $CineIndoTextB = styled.div`
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 600;
  margin-top: -8px;
  text-wrap: nowrap;
`;
export const $EcommBillContainer = styled.div`
  background-color: ${(props) => props.backgroundColor};
  border-radius: ${(props) => props.borderRadius};
  margin: ${(props) => props.margin};
  p {
    margin: 0px 0px 8px;
  }
  p,
  td {
    font-size: 12px;
  }

  .header,
  th {
    font-size: 14px;
  }
`;
export const $Bold = styled.span`
  font-weight: bold;
`;
export const $SemiBold = styled.span`
  font-weight: 550;
`;

export const Capitalize = styled.span`
  text-transform: capitalize;
`;

export const VerticalAlign = styled.span`
  vertical-align: top;
`;

export const UpperCase = styled.span`
  text-transform: uppercase;
`;

export const $Button = styled.button.attrs(() => ({
  type: 'button',
}))`
  background-color: transparent;
  border: none;
  margin: ${({ margin }) => margin || '0px'};

  ${({ variant }) =>
    variant === 'text' &&
    css`
      border-bottom: 1px solid ${({ theme }) => theme.color.doveGray};
      color: ${({ theme }) => theme.color.doveGray};
      padding: 0px;
    `}

  ${({ variant }) =>
    variant === 'success' &&
    css`
      background-color: ${({ theme }) => theme.color.success};
      color: ${({ theme }) => theme.color.white};
      padding: 8px 24px;
      border-radius: 4px;
    `}

    ${({ variant }) =>
    variant === 'primary-outline' &&
    css`
      background-color: ${({ theme }) => theme.color.white};
      color: ${({ theme }) => theme.color.primary};
      border: 1px solid ${({ theme }) => theme.color.primary};
      padding: 8px 24px;
      border-radius: 4px;
    `}

    ${({ variant, bgColor, color }) =>
    variant === 'custom' &&
    css`
      padding: 8px 12px;
      border-radius: 4px;
      background-color: ${bgColor};
      color: ${color};
    `}
`;

export const $NYBActionButton = styled($Button)`
  &&& {
    background-color: ${({ bgColor }) => bgColor};
    padding: 8px 16px;
    color: ${({ color }) => color || '#fff'};
    border-radius: 8px;
    width: ${({ width }) => width || '100%'};
    font-weight: bold;
  }
`;

export const $NYBComments = styled.textarea`
  resize: none;
  padding: 8px;
  box-sizing: border-box;
  width: 100%;

  ${({ isError }) =>
    isError &&
    css`
      border: 1px solid red;
    `}
`;

export const $SocialLink = styled.a`
  font-weight: 400;
  margin: 0px 0px 8px;
  font-size: 11px;
  color: #313131;
  text-align: center;
  text-decoration: none;
  min-width: 100px;
`;

export const $POBButton = styled($Button)`
  position: relative;
  left: 50%;
  transform: translateX(calc(-50% - 12px));
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ txtColor }) => txtColor};
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  margin: 0 12px 12px;
`;

export const $TnCContent = styled.div`
  padding: 12px 8px;
  max-height: 400px;
  overflow: auto;
`;

export const $PrasadsTnCContent = styled($TnCContent)`
  ul {
    padding: 0px 20px 0px 32px;
  }

  p {
    font-size: ${({ revertStyle }) => (revertStyle ? '14px' : '18px')};
    text-decoration: ${({ revertStyle }) => (revertStyle ? 'none' : 'underline')};
    font-weight: ${({ revertStyle }) => (revertStyle ? '100' : '800')};
    margin-left: 8px;
  }
`;

export const $DecathStoreExit = styled($Flexbox)`
  flex-basis: 75%;

  div {
    padding: 0px ${({ theme }) => theme.spacing.l};
  }

  & > div:first-child {
    border-right: 1px solid ${({ theme }) => theme.color.light};
  }

  div p {
    text-align: center;
    font-size: 14px;
    margin: 0px;
  }

  div > p:first-child {
    font-size: 18px;
    margin-top: ${({ theme }) => theme.spacing.s};
    margin-bottom: ${({ theme }) => theme.spacing.m};
  }

  .store-exit-label {
    color: ${({ theme }) => theme.color.denim};
  }
`;

export const $DecathlonSecurityCheckIndicator = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
  width: 8px;
  height: 100%;
  background-color: ${({ theme }) => theme.color.denim};
  border-radius: 2px 0px 0px 2px;
`;

export const Dash = styled.div`
  background-repeat: no-repeat;
  height: ${({ height }) => height || '1px'};
  background: linear-gradient(
    90deg,
    ${({ color }) => color || '#707070'} ${({ dashLength }) => dashLength || '50%'},
    #fff 0
  );
  background-size: ${({ type }) => (type === 'thick' ? '12px 1px' : '8px 1px')};
  margin: ${({ margin }) => margin || '10px 0px'};
`;

export const RemovePrintHeaderFooter = styled.div`
  @media print {
    @page {
      margin: 0;
    }
    body {
      margin: 0.6cm;
    }
  }
`;
