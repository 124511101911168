import React from 'react';
import { MediaPrintStyle } from '../../../css/styles';
import { $Container, $RzpContainer } from '../../templates/styles';
import addPropsToChildren from '../../../utils/addPropsToChildren';
import { SuspenseLoading } from '../../SuspenseLoading';
import { bgCampaign, isBannerinBill } from 'utils/campaignbg';

const getStyleProps = (
  {
    isMiraj,
    isDecathlonIndiaEcom,
    isCinepolisGroup,
    isBK,
    isFunCinemas,
    isShree,
    isCarnival,
    isConnplex,
    isPrasads,
    isRawMango,
    isCinepolisFoodBill,
    isHdfChargeSlip,
    isNyCinemas,
    isManyavar,
    isWowMomos,
    isGoColors,
    isRzpFtx25,
  },
  parsedData,
  campaignDetails,
) => {
  if (isConnplex) {
    if (parsedData?.customData?.ticketType === 'CINEPOLIS_TICKET')
      return { id: 'connplex-body1', className: 'ticket-bg' };
    else return { id: 'connplex-body1', className: 'food-bg' };
  } else if (isMiraj) {
    return { id: 'miraj-body' };
  } else if (isDecathlonIndiaEcom) {
    return { id: 'decathind-body' };
  } else if (isCarnival) {
    return { id: 'carnival-body' };
  } else if (isPrasads) {
    return { id: 'prasad-body' };
  } else if (isHdfChargeSlip) {
    return { id: 'hdfc-body' };
  } else if (isBK || isShree) {
    return { className: `bill-app ${isBK ? 'bk-bg' : ''}` };
  } else if (isNyCinemas) {
    return { id: 'nycine-body' };
  } else if (isGoColors) {
    return { id: 'goColors-body' };
  } else if (isManyavar) {
    return { id: 'manyavar-body' };
  } else if (isWowMomos) {
    return { id: 'wowmomos-body' };
  } else if (isRzpFtx25) {
    return { id: 'rzpftx-body' };
  } else if (isCinepolisGroup) {
    return {
      id: 'cinepolis-body',
      className: isFunCinemas ? 'funcinemas-body' : isCinepolisFoodBill ? 'cineFoodBgrd' : '',
    };
  } else if (isRawMango) {
    return { className: 'rawmango-body' };
  } else {
    return [];
  }
};

const Layout = ({ children, ...rest }) => {
  let image = '';
  let value = bgCampaign(rest.campaignDetails, rest.parsedData).backgroundImage;
  let bannerinBillisTrue = isBannerinBill(rest.campaignDetails);
  if (value.length > 0) {
    image = `url(${value})`;
  }
  return (
    <>
      <React.Suspense fallback={<SuspenseLoading />}>
        <MediaPrintStyle />
        {rest.flags && rest.flags.isRzpPos ? (
          <$RzpContainer
            {...getStyleProps(rest.flags, rest.parsedData, rest.campaignDetails)}
            backgroundImage={image}
            backgroundSize={bgCampaign(rest.campaignDetails, rest.parsedData).backgroundSize}>
            {addPropsToChildren(children, { ...rest })}
          </$RzpContainer>
        ) : (
          <$Container
            {...getStyleProps(rest.flags, rest.parsedData, rest.campaignDetails)}
            backgroundImage={image}
            backgroundSize={bgCampaign(rest.campaignDetails, rest.parsedData).backgroundSize}>
            {addPropsToChildren(children, { ...rest })}
          </$Container>
        )}
      </React.Suspense>
    </>
  );
};

export default Layout;
